<template>
  <div
    class="actions-cell--root"
    v-if="route.name !== 'competitions_subscription'"
  >
    <template v-if="!isEventFinished">
      <div v-if="!isEventBooked && !eventDisabled">
        <Icon
          v-if="bookingActionError"
          name="alert"
          class="table-cell-booking-error"
        />
        <Button
          v-if="isEventBookingSupported && !bookingActionError"
          @click="bookEvent"
          tabindex="-1"
          small
          :loading="bookingActionLoading"
        >
          Book
        </Button>
      </div>
      <div v-if="isEventBooked && !isManagedEvent && !eventDisabled">
        <Button
          v-if="!isEventSuspended"
          variant="danger-secondary"
          class="buton--icon"
          @click="toggleEventSuspend(true)"
          :loading="isSuspendEventActionLoading"
          small
        >
          <Icon
            v-if="!isSuspendEventActionLoading"
            :name="'pause'"
          />
        </Button>
        <Button
          v-else
          variant="secondary"
          class="buton--icon"
          @click="toggleEventSuspend(false)"
          :loading="isSuspendEventActionLoading"
          small
        >
          <Icon
            v-if="!isSuspendEventActionLoading"
            :name="'play'"
          />
        </Button>
      </div>
    </template>
    <div v-if="isEventBooked && isManagedEvent" /> <!-- Fill space when there is no suspend button -->
    <div>
      <div
        class="table-cell-context-menu-button"
        @click="handleContextMenu"
      >
        <Icon name="more-dots" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import * as api from '@/services/api';
import sportIds from '@/services/helpers/sports';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Button,
    Icon,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const bookingActionLoading = ref(false);
    const bookingActionError = ref(false);
    const isEventBookingSupported = computed(() => props.event.eventIsCoveredsByEventId?.nodes?.[0]?.available);
    const isEventBooked = computed(() => props.event.booked);
    const isEventSuspended = computed(() => props.event.isSuspended);
    const isSuspendEventActionLoading = computed(() => store.getters.suspendEventActionLoading(props.event.eventId));
    const isPlayerSetupAvailable = computed(() => (props.event.sportId === FOOTBALL_ID || props.event.sportId === BASKETBALL_ID));
    const isManagedEvent = computed(() => props.event.hasDpsPrices); // managed are DPS events and we check if dps has prices to confirm its dps event

    const toggleEventSuspend = (isSuspended) => {
      store.dispatch('toggleEventSuspend', { eventId: props.event.eventId, isSuspended });
    };
    const isHovered = inject('isHovered');
    const isEventFinished = computed(() => props.event.matchState === 'FINISHED');

    const eventDisabled = computed(() => props.event.limbo);

    const bookEvent = () => {
      bookingActionLoading.value = true;
      bookingActionError.value = false;
      const { eventId } = props.event;
      api.bookEvent({ eventId })
        .then(() => {
          store.dispatch('loadEvents');
          store.dispatch('addNotification', {
            message: 'Successfully booked event(s)',
            type: 'success',
            duration: 5000,
          });
        })
        .catch(() => {
          bookingActionError.value = true;
        })
        .finally(() => {
          bookingActionLoading.value = false;
          store.dispatch('updateSelectedEvents', []);
        });
    };

    const showContextMenu = inject('showContextMenu');
    const handleContextMenu = (event) => {
      event.preventDefault();
      event.stopPropagation();
      showContextMenu({
        top: event.y,
        left: event.x,
        row: props.event,
      });
    };

    return {
      route,
      isEventSuspended,
      isEventBookingSupported,
      isEventBooked,
      isSuspendEventActionLoading,
      isPlayerSetupAvailable,
      bookingActionLoading,
      bookingActionError,
      toggleEventSuspend,
      bookEvent,
      isHovered,
      handleContextMenu,
      isEventFinished,
      isManagedEvent,
      eventDisabled,
    };
  },
};
</script>

<style lang="scss">
.event-table .table-body .table-cell.table-cell--actions .actions-cell--root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .table-cell-booking-error {
    stroke: red;
  }

  .table-cell-context-menu-button {
    height: 33px;
    padding: 0.65rem;
  }
  .button.buton--icon {
    padding: 0px 0px 0px 4px;
  }
  .button.button--danger-secondary:not(.button--disabled) {
    .icon {
      svg path {
        fill: $error500;
      }
    }
  }
}
</style>
