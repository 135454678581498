<template>
  <div class="offering-cell">
    <span v-if="!coreAndDerivativesBookingInfo">
      -
    </span>
    <Chip
      v-if="bookedForPrematch"
      :variant="'blue'"
    >
      PM
    </Chip>
    <Chip
      v-if="bookedForLive"
      :variant="'green'"
    >
      LIVE
    </Chip>
  </div>
</template>

<script>
import { computed } from 'vue';
import Chip from '@/components/common/Chip';

export default {
  components: {
    Chip,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const coreAndDerivativesBookingInfo = computed(() => props.event?.bookedEvents?.nodes[0]);
    const bookedForPrematch = computed(() => coreAndDerivativesBookingInfo.value?.isOfferedPreMatch);
    const bookedForLive = computed(() => coreAndDerivativesBookingInfo.value?.isOfferedLive);

    return {
      coreAndDerivativesBookingInfo,
      bookedForPrematch,
      bookedForLive,
    };
  },
};
</script>

<style lang="scss">
.offering-cell {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
