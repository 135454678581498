<template>
  <component
    :is="componentName"
    :column-key="columnKey"
    :event="event"
  />
</template>

<script>
import { computed } from 'vue';
import EventTableGameCell from './EventTableGameCell';
import EventTableSportCell from './EventTableSportCell';
import EventTableDateTimeCell from './EventTableDateTimeCell';
import EventTableStatusCell from './EventTableStatusCell';
import EventTablePriceCell from './EventTablePriceCell';
import EventTableMappedCell from './EventTableMappedCell';
import EventTableActionsCell from './EventTableActionsCell';
import EventTableFallbackCell from './EventTableFallbackCell';
import EventTableTemplateCell from './EventTableTemplateCell';
import EventTableRegionCell from './EventTableRegionCell';
import EventTableCompetitionCell from './EventTableCompetitionCell';
import EventTableHighlightCell from './EventTableHighlightCell';
import EventTableFeaturesCell from './EventTableFeaturesCell';
import EventTableApprovedCell from './EventTableApprovedCell';
import EventTableBookingCell from './EventTableBookingCell';
import EventTableOfferingCell from './EventTableOfferingCell';

const components = {
  eventName: EventTableGameCell,
  sport: EventTableSportCell,
  startsAt: EventTableDateTimeCell,
  matchState: EventTableStatusCell,
  price: EventTablePriceCell,
  mapped: EventTableMappedCell,
  actions: EventTableActionsCell,
  fallback: EventTableFallbackCell,
  sportTemplate: EventTableTemplateCell,
  highlight: EventTableHighlightCell,
  region: EventTableRegionCell,
  competition: EventTableCompetitionCell,
  features: EventTableFeaturesCell,
  approved: EventTableApprovedCell,
  booking: EventTableBookingCell,
  offering: EventTableOfferingCell,
};

export default {
  components,
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const componentName = computed(() => components[props.columnKey] || 'fallback');

    return {
      componentName,
    };
  },
};
</script>
